import dashboard from './dashboard';
// import pages from './pages';
import Print from './print';
import students from './Students';
import employe from './Employe';
import notice from './notice';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, employe, students, notice, Print]
};

export default menuItems;
