import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import PublicHeader from 'views/pages/Public/Header/PublicHeader';
// import PublicFooter from 'views/pages/Public/Footer/PublicFooter';

// project imports

// material-ui
import { useTheme } from '@mui/material/styles';

// assets
import { Box, Container, Stack } from '@mui/system';

// import MenuList from 'views/pages/Public/Header/MenuList';
const FacebookChat = lazy(() => import('views/pages/Public/Footer/FacebookChat'));
const PublicFooter = lazy(() => import('views/pages/Public/Footer/PublicFooter'));

// styles

// ==============================|| Public LAYOUT ||============================== //

const PublicLayout = () => {
    const theme = useTheme();
    // Handle left drawer
    return (
        <Stack theme={theme} sx={{ backgroundColor: '#F0F0F0', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <PublicHeader />

            <Container maxWidth="lg">
                <Outlet />
            </Container>
            <Stack sx={{ flexGrow: 1 }} />

            <Suspense fallback={<div>Chat Loading...</div>}>
                <FacebookChat />

            </Suspense>
            <Suspense fallback={<div>Footer Loading...</div>}>
                <PublicFooter sx={{ mt: 'auto' }} />
            </Suspense>
        </Stack>
    );
};

export default PublicLayout;
