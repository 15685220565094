import axios from 'axios';


const defaultBaseURL = window.location.hostname === 'localhost' ? 'http://localhost:3131/' : 'https://chs-server.vercel.app/';
const API = axios.create({
    baseURL: defaultBaseURL,
    headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('authToken'),
        email: localStorage.getItem('email'),
    },

});

// Add a response interceptor
API.interceptors.response.use(
    response => {
        if (response.status === 401) {
            localStorage.removeItem('authToken');
            localStorage.removeItem('email');
        }
        return response;
    },
    error => {
        if (error.response.status === 401) {
            localStorage.removeItem('authToken');
            localStorage.removeItem('email');
        }
        return Promise.reject(error);
    }
);

export default API;