import { lazy, React } from 'react';
import PublicLayout from 'layout/PublicLayout/PublicLayout';
import Public from 'views/pages/authentication/auth-forms/public/Public';

// project imports
import Loadable from 'ui-component/Loadable';

// login option 3 routing
const Home = Loadable(lazy(() => import('views/pages/Public/Home/Home')));
const TeachersInfo = Loadable(lazy(() => import('views/pages/Public/TeachersInfo/TeachersInfo')));
const Contact = Loadable(lazy(() => import('views/pages/Public/Contact/Contact')));
const History = Loadable(lazy(() => import('views/pages/Public/History/History')));
const Gallery = Loadable(lazy(() => import('views/pages/Public/Gallery/Gallery')));
const Admission = Loadable(lazy(() => import('views/pages/Public/Admission/Admission')));
const Notice = Loadable(lazy(() => import('views/pages/Public/Notice/Notice')));
const Result = Loadable(lazy(() => import('views/pages/Public/Result/Result')));

const PublicRoutes = {
    path: '/',
    element: (
        <Public>
            <PublicLayout />
        </Public>
    ),
    children: [
        {
            path: '/',
            element: <Home />
        },
        {
            path: 'teachers-info',
            element: <TeachersInfo />
        },
        {
            path: 'contact',
            element: <Contact />
        },
        {
            path: 'history',
            element: <History />
        },
        {
            path: 'gallery',
            element: <Gallery />
        },
        {
            path: 'admission',
            element: <Admission />
        },
        {
            path: 'notice',
            element: <Notice />
        },
        {
            path: 'result',
            element: <Result />
        },
        {
            path: '*',
            element: <Home />
        }
    ]
};

export default PublicRoutes;
