/* eslint-disable react/prop-types */
import { AuthContext } from 'context/UserContext';
import React from 'react';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router';
import Loader from 'ui-component/Loader';

const Public = ({ children }) => {
    const { user, loading } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const from = location.state?.from?.pathname || '/dashboard';

    const validUser = ['charupath01@gmail.com', 'musamolla3131@gmail.com', 'chsacbd@gmail.com', 'info@chs.ac.bd', 'charupathschoolbr1@gmail.com'];

    const [valid] = validUser.filter((validEmail) => {
        if (user?.email === validEmail) {
            return true;
        } else {
            return false;
        }
    })

    if (valid) {
        return ((location.pathname === '/login') || (location.pathname === '/register')) ? (navigate(from, { replace: true })) : children;
    }
    if (!user || !user.uid) {
        return children;
    }

};

export default Public;
