// assets
import { IconDashboard, IconCurrencyTaka, IconPrinter, IconMessage2 } from '@tabler/icons';

// constant

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'CollectFees',
            title: 'Collect Fees',
            type: 'item',
            url: '/student/fees',
            icon: IconCurrencyTaka,
            breadcrumbs: false
        },
        {
            id: 'CollectedFeesSlip',
            title: 'Collected Fees Slip ',
            type: 'item',
            url: '/students/collected-fees-slip',
            icon: IconPrinter,
            breadcrumbs: false
        },
        {
            id: 'Messaging',
            title: 'Messaging',
            type: 'item',
            url: 'messaging',
            icon: IconMessage2,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
