import React, { useContext, useEffect, useState } from 'react';
import MenuList from './MenuList';
import Container from '@mui/material/Container';
import { Box, Stack } from '@mui/system';
import { Button, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import LoginIcon from '@mui/icons-material/Login';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useLocation, useNavigate } from 'react-router';

import SchoolLogo from '../../../../assets/images/School logo Color.png';
import mujib from '../../../../assets/images/mujib100.png';
import mujib1 from '../../../../assets/images/mujib100-1.png';
import { Link, Navigate } from 'react-router-dom';
import { AuthContext } from 'context/UserContext';
import Drawer from '@mui/material/Drawer';
import './PublicHeader.css';
import ScrollingNotices from './ScrollingNotices';
import diploma from './stickers/diploma.png';
import idea from './stickers/idea.png';
import university from './stickers/university.png';
import test from './stickers/test.png';
import place from './stickers/1st-place.png';
import atom from './stickers/atom3.png';

const PublicHeader = () => {
    const navigate = useNavigate();
    const { user, } = useContext(AuthContext);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [open, setOpen] = React.useState(false); // New state variable for the drawer's open/close state

    const toggleDrawer = () => {
        setOpen(!open); // Toggles the value of 'open'
    };

    const list = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
            <MenuList toggleDrawer={true} />
        </Box>
    );

    const iconUrls = [
        idea,
        place,
        test,
        atom,
        diploma,

    ];
    const randomIconUrl = iconUrls[Math.floor(Math.random() * iconUrls.length)];

    let positions = new Set();

    const randomPosition = () => {
        let newPosition;
        let newX, newY;

        do {
            newX = Math.floor(Math.random() * 100);
            newY = Math.floor(Math.random() * 100);
            newPosition = `${newX}% ${newY}%`;
        } while (((newX >= 15 && newX <= 60) && (newX >= 0 && newX <= 8) && (newX >= 90 && newX <= 100)) || checkOverlap(newPosition));

        positions.add(newPosition);

        return newPosition;
    };

    const checkOverlap = (newPosition) => {
        // Convert the position string to numbers
        const [newX, newY] = newPosition.split(' ').map(str => parseInt(str));

        // Check if the new position overlaps with any existing position
        for (const position of positions) {
            const [x, y] = position.split(' ').map(str => parseInt(str));

            // If the new position is within 10% of an existing position, return true
            if (Math.abs(newX - x) < 15 && Math.abs(newY - y) < 15) {
                return true;
            }
        }

        // If the new position does not overlap with any existing position, return false
        return false;
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    let backgroundImage = `linear-gradient(rgba(22, 30, 44, 0.35), rgba(22, 30, 44, 0.35)), url(${iconUrls[0]}), url(${iconUrls[1]}), url(${iconUrls[2]}), url(${iconUrls[3]}), url(${iconUrls[4]})`;
    let backgroundSize = 'cover, 7em, 6em, 8em, 7em, 7em';
    let backgroundPosition = 'center, ' + '01% 10%, 13% 90%, 69% 35%, 89% 50%, 99% 90%';

    if (windowWidth < 1200 && windowWidth > 1024) { // 768px is a common breakpoint for mobile devices
        backgroundImage = `linear-gradient(rgba(22, 30, 44, 0.35), rgba(22, 30, 44, 0.35)), url(${iconUrls[2]}), url(${iconUrls[3]}), url(${iconUrls[4]})`;
        backgroundSize = 'cover, 6em, 5em, 5em';
        backgroundPosition = 'center, ' + '69% 35%, 89% 50%, 99% 90%';
    } else if (windowWidth <= 1024 && windowWidth > 768) { // 768px is a common breakpoint for mobile devices
        backgroundImage = `linear-gradient(rgba(22, 30, 44, 0.35), rgba(22, 30, 44, 0.35)), url(${iconUrls[2]}), url(${iconUrls[3]})`;
        backgroundSize = 'cover, 4em, 3.5em';
        backgroundPosition = 'center, ' + '69% 35%, 89% 50%';
    } else if (windowWidth <= 768) { // 768px is a common breakpoint for mobile devices
        backgroundImage = `linear-gradient(rgba(22, 30, 44, 0.35), rgba(22, 30, 44, 0.35)), url(${iconUrls[3]})`;
        backgroundSize = 'cover, 3.5em';
        backgroundPosition = 'center, ' + '78% 50%';
    }

    return (
        <>

            <Drawer id="mobileMenu1" anchor="right" open={open} onClose={toggleDrawer}>
                {list}
            </Drawer>
            <Box className="headerBanar"
                sx={{
                    backgroundImage: backgroundImage,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: backgroundSize,
                    backgroundPosition: backgroundPosition,
                    backgroundColor: '#161E2C',
                }}

            // sx={{
            //     backgroundImage: `linear-gradient(rgba(22, 30, 44, 0.4), rgba(22, 30, 44, 0.4)), url(${iconUrls[0]}), url(${iconUrls[1]}), url(${iconUrls[2]}), url(${iconUrls[3]}), url(${iconUrls[4]})`,
            //     backgroundRepeat: 'no-repeat',
            //     backgroundSize: 'cover, 7em, 6em, 8em, 7em, 7em',
            //     backgroundPosition: 'center, ' + '01% 10%, 13% 90%, 69% 35%, 89% 50%, 99% 90%',
            //     // backgroundPosition: 'center, ' + `01% 10%, ${randomPosition()}, ${randomPosition()}, ${randomPosition()}, 99% 90%`,
            //     backgroundColor: '#161E2C',
            // }}
            >
                <Container maxWidth="lg">
                    <Stack direction="row" justifyContent={'space-between'} sx={{ paddingY: 1 }}>
                        <Stack direction="row" justifyContent={'space-between'} spacing={4} alignItems={'center'} sx={{ paddingY: 1 }}>
                            <img
                                id="schoolLogo"
                                src={SchoolLogo}
                                alt="CHS_Logo"
                                width="122rem"
                            // style={{
                            //     filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.5))'
                            // }}
                            />
                            <Stack direction="column" alignItems={'start'}>
                                <Typography
                                    variant="h1"
                                    color={'#F0F0F0'}
                                    id="SchoolNameEnglish"
                                    sx={{
                                        fontFamily: 'Oswald',
                                        fontSize: '3.1rem',
                                        letterSpacing: '-0.1rem',
                                        mt: '0.9rem',
                                        color: '#F0F0F0',
                                        textShadow: '0 0 20px #3C486B',
                                    }}
                                >
                                    {'Charupath Hateykhari School'}
                                </Typography>

                                <Typography
                                    variant="h1"
                                    color={'#F0F0F0'}
                                    id="SchoolNameBangla"
                                    // className="KongshoMJ"
                                    sx={{
                                        fontFamily: 'KongshoMJ',
                                        fontSize: '4rem',
                                        letterSpacing: '0.1rem',
                                        color: '#F0F0F0',
                                        textShadow: '0 0 20px #3C486B',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    <span style={{ color: '#F9D949' }}>Pviæ</span>cvV nv‡ZLwo <span className='banglaSchool'>¯‹yj</span>
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack direction="column" justifyContent={'space-evenly'} alignItems={'end'}>
                            <Stack direction="row" justifyContent={'end'} alignItems={'center'}>
                                <Box id="mobileMenu">
                                    <Button color="secondary" size="large" onClick={toggleDrawer}>
                                        <MenuIcon />
                                    </Button>
                                </Box>
                                <Box id="AuthLog" >
                                    {user ? (
                                        <Button
                                            fullWidth
                                            variant='contained' color="secondary"
                                            endIcon={<DashboardIcon />}
                                            onClick={() => {
                                                navigate('/dashboard');
                                            }}
                                        >
                                            Dashboard
                                        </Button>
                                    ) : (
                                        <Button
                                            fullWidth
                                            variant='contained' color="secondary"
                                            endIcon={<LoginIcon />}
                                            onClick={() => {
                                                navigate('/login');
                                            }}
                                        >
                                            Login
                                        </Button>)}
                                </Box>
                            </Stack>
                            {/*
                            <Stack
                                id="headerBanarRightImg"
                                direction="row"
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                sx={{ p: 1 }}
                            >
                                <img src={mujib} alt="CHS_Logo" width="100rm" />
                                <img src={mujib1} alt="CHS_Logo" width="100rm" />
                            </Stack> */}
                        </Stack>
                    </Stack>
                </Container>
            </Box>
            <Box id="desktopMenu" sx={{ display: 'block', position: 'sticky', top: 0, zIndex: '100', backgroundColor: 'white' }}>
                <MenuList toggleDrawer={false} />
            </Box>
            <Box sx={{ backgroundColor: '#F0F0F0' }}>
                <ScrollingNotices />
                <Container maxWidth="lg">
                </Container>
            </Box>
        </>
    );
};

export default PublicHeader;
