import { useRoutes } from 'react-router-dom';

// routes
import PrivateRoutes from './PrivateRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import PublicRoutes from './PublicRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([PublicRoutes, PrivateRoutes, AuthenticationRoutes]);
}
