/* eslint-disable react/prop-types */
import * as React from 'react';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';

// Icon Import
import HomeIcon from '@mui/icons-material/Home';
import HistoryIcon from '@mui/icons-material/History';
import CollectionsIcon from '@mui/icons-material/Collections';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import ContactsIcon from '@mui/icons-material/Contacts';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { ButtonGroup } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import LoginIcon from '@mui/icons-material/Login';
import DashboardIcon from '@mui/icons-material/Dashboard';
import UserContext, { AuthContext } from 'context/UserContext';

export default function MenuList({ toggleDrawer }) {
    const { user } = UserContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation().pathname;

    return (
        <>
            {toggleDrawer ?
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                        margin: '0.5rem'
                    }}
                >
                    {user ? (
                        <Button
                            size="small"
                            fullWidth
                            color="secondary"
                            variant={`${location === '/dashboard' ? 'contained' : 'outlined'}`}
                            onClick={() => {
                                navigate('/dashboard');
                            }}
                            startIcon={<DashboardIcon />}
                        >
                            Dashboard
                        </Button>
                    ) : (null)}
                    <Button
                        size="small"
                        fullWidth
                        color="secondary"
                        variant={`${location === '/' ? 'contained' : 'outlined'}`}
                        onClick={() => {
                            navigate('/');
                        }}
                        startIcon={<HomeIcon />}

                    >
                        {/* মূলপাতা */}
                        Home
                    </Button>

                    <Button
                        size="small"
                        fullWidth
                        color="secondary"
                        variant={`${location === '/history' ? 'contained' : 'outlined'}`}
                        onClick={() => {
                            navigate('/history');
                        }}
                        startIcon={<HistoryIcon />}
                    >
                        History
                        {/* ইতিহাস */}
                    </Button>

                    <Button
                        size="small"
                        fullWidth
                        color="secondary"
                        variant={`${location === '/gallery' ? 'contained' : 'outlined'}`}
                        startIcon={<CollectionsIcon />}
                        onClick={() => {
                            navigate('/gallery');
                        }}
                    >
                        Gallery
                        {/* ছবিঘর */}
                    </Button>

                    <Button
                        size="small"
                        fullWidth
                        color="secondary"
                        variant={`${location === '/admission' ? 'contained' : 'outlined'}`}
                        startIcon={<LibraryBooksIcon />}
                        onClick={() => {
                            navigate('/admission');
                        }}
                    >
                        {/* ভর্তি */}
                        Admission
                    </Button>

                    <Button
                        size="small"
                        fullWidth
                        color="secondary"
                        variant={`${location === '/result' ? 'contained' : 'outlined'}`}
                        startIcon={<PendingActionsIcon />}
                        onClick={() => {
                            navigate('/result');
                        }}
                    >
                        {/* ফলাফল */}
                        Result
                    </Button>
                    <Button
                        size="small"
                        fullWidth
                        color="secondary"
                        variant={`${location === '/teachers-info' ? 'contained' : 'outlined'}`}
                        startIcon={<RecentActorsIcon />}
                        onClick={() => {
                            navigate('/teachers-info');
                        }}
                    >
                        {/* শিক্ষকদের তথ্য */}
                        Teachers info
                    </Button>

                    <Button
                        size="small"
                        fullWidth
                        color="secondary"
                        variant={`${location === '/contact' ? 'contained' : 'outlined'}`}
                        startIcon={<ContactsIcon />}
                        onClick={() => {
                            navigate('/contact');
                        }}
                    >
                        {/* যোগাযোগ */}
                        Contact
                    </Button>
                    <Button
                        size="small"
                        fullWidth
                        color="secondary"
                        variant={`${location === '/notice' ? 'contained' : 'outlined'}`}
                        onClick={() => {
                            navigate('/notice');
                        }}
                        startIcon={<NotificationsActiveIcon />}
                    >
                        {/* নোটিশ */}
                        Notice
                    </Button>
                    {user ? (
                        // <Button
                        //     size="small"
                        //     fullWidth
                        //     color="secondary"
                        //     variant={`${location === '/dashboard' ? 'contained' : 'outlined'}`}
                        //     onClick={() => {
                        //         navigate('/dashboard');
                        //     }}
                        //     startIcon={<DashboardIcon />}
                        // >
                        //     Dashboard
                        // </Button>
                        null
                    ) : (
                        <Button
                            size="small"
                            fullWidth
                            color="secondary"
                            variant={`${location === '/login' ? 'contained' : 'outlined'}`}
                            onClick={() => {
                                navigate('/login');
                                console.log("Login Clicked")
                            }}
                            startIcon={<LoginIcon />}
                        >
                            Login
                        </Button>
                    )}
                </Box>
                :
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'sticky',
                        top: '0',
                        left: '0',
                        zIndex: '100',
                        gap: '1rem',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                        borderRadius: '0',
                        overflow: 'hidden',
                        // backgroundColor: 'red',
                        // boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                        // margin: '0.5rem 0'
                    }}
                >
                    <ButtonGroup color='secondary' orientation={toggleDrawer ? "vertical" : "horizontal"} fullWidth sx={{ backgroundColor: '#373A4D', borderRadius: '0', overflow: 'hidden' }}>
                        <Button

                            fullWidth
                            variant={`${location === '/' ? 'contained' : 'outlined'}`}
                            onClick={() => {
                                navigate('/');
                            }}
                            startIcon={<HomeIcon />}

                        >
                            {/* মূলপাতা */}
                            Home
                        </Button>

                        <Button
                            fullWidth
                            variant={`${location === '/history' ? 'contained' : 'outlined'}`}
                            onClick={() => {
                                navigate('/history');
                            }}
                            startIcon={<HistoryIcon />}
                        >
                            History
                            {/* ইতিহাস */}
                        </Button>

                        <Button
                            fullWidth
                            variant={`${location === '/gallery' ? 'contained' : 'outlined'}`}
                            startIcon={<CollectionsIcon />}
                            onClick={() => {
                                navigate('/gallery');
                            }}
                        >
                            Gallery
                            {/* ছবিঘর */}
                        </Button>

                        <Button
                            fullWidth
                            variant={`${location === '/admission' ? 'contained' : 'outlined'}`}
                            startIcon={<LibraryBooksIcon />}
                            onClick={() => {
                                navigate('/admission');
                            }}
                        >
                            {/* ভর্তি */}
                            Admission
                        </Button>

                        <Button
                            fullWidth
                            variant={`${location === '/result' ? 'contained' : 'outlined'}`}
                            startIcon={<PendingActionsIcon />}
                            onClick={() => {
                                navigate('/result');
                            }}
                        >
                            {/* ফলাফল */}
                            Result
                        </Button>
                        <Button
                            fullWidth
                            variant={`${location === '/teachers-info' ? 'contained' : 'outlined'}`}
                            startIcon={<RecentActorsIcon />}
                            onClick={() => {
                                navigate('/teachers-info');
                            }}
                        >
                            {/* শিক্ষকদের তথ্য */}
                            Teachers info
                        </Button>

                        <Button
                            fullWidth
                            variant={`${location === '/contact' ? 'contained' : 'outlined'}`}
                            startIcon={<ContactsIcon />}
                            onClick={() => {
                                navigate('/contact');
                            }}
                        >
                            {/* যোগাযোগ */}
                            Contact
                        </Button>
                        <Button
                            fullWidth
                            variant={`${location === '/notice' ? 'contained' : 'outlined'}`}
                            onClick={() => {
                                navigate('/notice');
                            }}
                            startIcon={<NotificationsActiveIcon />}
                        >
                            {/* নোটিশ */}
                            Notice
                        </Button>
                        {/* </div> */}
                    </ButtonGroup>
                </Box>
            }
        </>
    );
}
