/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, ButtonBase } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
// import NotificationSection from './NotificationSection';
import IconButton from '@mui/material/IconButton';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

// assets
import { IconMenu2 } from '@tabler/icons';
import SearchBar from './SearchSection/SearchBar';
import { useState } from 'react';
// import { useContext } from 'react';
// import { AuthContext } from 'context/UserContext';
import { useEffect } from 'react';
import { MaterialUISwitch } from './MUI switch';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    // const { light, setLight } = useContext(AuthContext);
    const theme = useTheme();

    const [isFullScreen, setIsFullScreen] = useState(false);

    const themeMode = {
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        transition: 'all .2s ease-in-out',
        background: theme.palette.secondary.light,
        color: theme.palette.secondary.main,
        '&:hover': {
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.light
        }
    };
    // const [themeMode, setThemeMode] = useState({
    //     ...theme.typography.commonAvatar,
    //     ...theme.typography.mediumAvatar,
    //     transition: 'all .2s ease-in-out',
    //     background: theme.palette.secondary.dark,
    //     color: theme.palette.secondary.main,
    //     '&:hover': {
    //         background: theme.palette.secondary.main,
    //         color: theme.palette.secondary.dark
    //     }
    // });

    // useEffect(() => {
    //     if (light) {
    //         setThemeMode({
    //             ...theme.typography.commonAvatar,
    //             ...theme.typography.mediumAvatar,
    //             transition: 'all .2s ease-in-out',
    //             background: theme.palette.secondary.light,
    //             color: theme.palette.secondary.main,
    //             '&:hover': {
    //                 background: theme.palette.secondary.main,
    //                 color: theme.palette.secondary.light
    //             }
    //         });
    //     } else {
    //         setThemeMode({
    //             ...theme.typography.commonAvatar,
    //             ...theme.typography.mediumAvatar,
    //             transition: 'all .2s ease-in-out',
    //             background: theme.palette.secondary.dark,
    //             color: theme.palette.secondary.main,
    //             '&:hover': {
    //                 background: theme.palette.secondary.main,
    //                 color: theme.palette.secondary.dark
    //             }
    //         });
    //     }
    // }, [light]);

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar variant="rounded" sx={themeMode} onClick={handleLeftDrawerToggle} color="inherit">
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search */}
            <SearchBar />
            <Box sx={{ flexGrow: 1 }} />

            {/* <NotificationSection /> */}

            {/* themee switch */}
            {/* <MaterialUISwitch
                onChange={() => {
                    setLight(!light);
                }}
            /> */}
            {isFullScreen ? (
                <IconButton
                    sx={{ marginX: 2 }}
                    color="secondary"
                    onClick={() => document.exitFullscreen().then(() => setIsFullScreen(false))}
                >
                    <FullscreenExitIcon />
                </IconButton>
            ) : (
                <IconButton
                    sx={{ marginX: 2 }}
                    color="secondary"
                    onClick={() => document.documentElement.requestFullscreen().then(() => setIsFullScreen(true))}
                >
                    <FullscreenIcon />
                </IconButton>
            )}

            <IconButton sx={{ marginX: 2 }} color="secondary" onClick={() => window.location.reload()}>
                <CachedIcon />
            </IconButton>

            <ProfileSection />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
